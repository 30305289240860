// common
// div:has(.spinner-border) {text-align: center; display: none !important;}
.spinner-border {display: none !important;}
.spinner-wrap .spinner-border {display: inline-block !important;}
.react-swipeable-view-container {min-height: 68vh;}
.Toastify__toast-container {z-index: 999900;}
.Toastify__toast-body { text-align: center; font-size: 16px; font-weight: 700; white-space: pre-wrap;}
.Toastify__close-button, .Toastify--animate-icon {display: none !important;}
.ReactModal__Body--open {overflow: hidden;}
.ReactModal__Overlay { transition: all 500ms ease-in-out; }
iframe .ElementsApp .InputElement[autocomplete="postal-code"] {width: 100px;}
.modal img[src="/img/i_close_g.png"] {cursor: pointer;}
.swiper-wrapper {align-items: center;}
.accordion-body p {margin-bottom: 0 !important;}
.border-13 {border: 1px solid #353745}
.card { transition: all,.4s; }
.avatar { background-size: cover; background-repeat: no-repeat; }
.avatar-md{width:36px;height:36px;font-size:1rem;}
.avatar-xxxl{width: 6rem; height: 6rem;}
.avatar.chat {background-color: #00759B; font-weight: 500;}
.form-check { display: flex; flex: 1; }
.form-check-label { flex: 1; }
// .ql-snow .ql-picker.ql-header .ql-picker-label::before, .ql-snow .ql-picker.ql-header .ql-picker-item::before {
//     font-size: 16px !important;
// }
.react-autocomplete-input { margin-top: 20px; max-height: 270px; overflow: scroll;
    li {color: #999; font-size: 10px; white-space: pre;}
    li.active {background-color: #00759B;}
    li:first-line {
        color: black; font-size: 12px;
    }
}
.pantone-text {color: #6768AB !important;}
.contents-wrap, .contents-inner-wrap { display: flex; flex-wrap: wrap;}
.contents-item {
    width: 100%; margin: 0; background-color: #16171C; color: #F1F1F1; border: 1px solid #353745; border-bottom: none; margin-bottom: 0; 
    .top-wrap {padding: 24px;}
    .thumb-wrap {padding: 0;
        .img-wrap {margin: 0;}
        .thumb {width: 100%; height: 380px; background-size: cover; background-position: center; background-repeat: no-repeat; display: flex; align-items: center; justify-content: center; overflow: hidden; position: relative;
            .spinner-wrap {width: 100%; height: 100%; position: absolute; display: flex; align-items: center; justify-content: center;}
        }
        .txt-wrap {padding: 20px 24px 10px;}
        .i_play {width: 100px; position: absolute; top: 42%; left: 42%;}
        a {color: white; text-decoration: underline;}
        iframe {width: 100%; height: 300px;}
    }
    .card-body {padding: 15px 24px 24px;}
    .ellipsis1, .ellipsis2, .ellipsis5 {width: auto;line-height: 21px;}
    .chatgpt-wraper { height: 400px; overflow: hidden;    
        header, header + div {display: none;}
        .chat-footer { top: 320px; width: 100%; background-image: linear-gradient(180deg, rgba(53, 55, 64, 0), #353740 58.85%);}
    }
}
.contents-item:last-child {border-bottom: 1px solid #353745;}
.btn-small {height: 33px; min-height: 33px; border-radius: 10rem; font-size: 12px !important; padding: 5px 20px;}
.filter-btns span{border:1px solid $yellow;padding:3px 19px;border-radius:10rem;line-height:30px;margin-right:1rem;margin-bottom:3px;color:$yellow;display:inline-block;font-weight: 500;cursor: pointer;}
.filter-btns span.active { background: $yellow; color: #121212; font-weight: 700;}
.no-data{border:1px solid #5a5e6a;border-radius:10px;text-align:center;padding:8rem;font-size:18px;color:#7f828c}
.tab-sticky { position: sticky; top: 60px; z-index: 1029; background: #121214;}
.chat-handle { cursor: move; padding-left: 0.5rem; z-index: 9; position: relative;}
.modal.confirm {
    .modal-footer { padding: 0; justify-content: space-around; border-top: 1px solid #E4E6EA;
        a {font-size: 16px; flex: 1; text-align: center; padding: 17px; margin: 0;}
        a:nth-child(2) { border-left: 1px solid #E4E6EA;}
    }
}
.side-bar {background-color: #16171C; padding: 40px; border-radius: 20px;
    .my-cnts { display: flex; align-items: center; justify-content: space-around;}
    .member-ship {background-color: #353745; padding: 20px; border-radius: 10px; display: flex; align-items: center; cursor: pointer;}
    .btn-small {display: none !important;}
}
.cam-wrap {position: relative; display: inline-block;
    .avatar-xl {width: 76px; height: 76px;}
    .i_camera {width: 24px; position: absolute; bottom: 0; right: 5px; cursor: pointer;}
}
.search-input { position: relative;
    .i_search {width: 24px; position: absolute; top: 10px; left: 22px; cursor: pointer; z-index: 1;}
    input {padding-left: 55px;}
    .i_close2 {width: 20px; position: absolute; top: 12px; right: 18px; cursor: pointer;}
}
.right-dummy {background-color: #353745; min-height: 38rem; display: block;}
.spinner-modal .modal-content { background-color: transparent; }
textarea::-webkit-scrollbar { width: 10px; height: 10px; display: block; }
.header {
    height: 64px; background-color: $black; border: none; align-items: center; justify-content: center; display: flex; border-bottom: 1px solid #353745;
    .logo {width: 82px; margin-top: 7px;}
    .header-nav {justify-content: flex-end; align-items: center;
        img {width: 24px;}
        .nav-link.register { color: #F6F7FA; font-weight: 500; border-radius: 100px; border: 1px solid #86899D; padding: 4px 12px !important;}
        .dropdown-menu { background-color: #3B3F4A; border-radius: 10px; min-width: 380px; padding: 0; overflow-y: scroll; margin-right: -4rem; margin-top: 20px;}
        .dropdown-divider { border-top: 1px solid #5A5E6A; margin-top: 0;}
        .dropdown-menu::-webkit-scrollbar { display: none; }
        .dropdown-menu::-webkit-scrollbar-thumb { background: rgba(0,0,0,0); }
    }
    .alarm {position: relative; 
        span{position:absolute;height:6px;width:6px;top:-2px;right:-2px;border-radius:100px;color:#fff;font-size:8px;line-height:14px;font-weight:700;text-align:center;background-color: #FF0000;}
    }
    .btn_login{display:flex;flex-direction:row;justify-content:center;align-items:center;width:77px;height:27px;border-radius:4px;font-size:12px;color:#fff;border:1px solid}
    .header-nav .nav-link:hover, .header-nav .nav-link:focus {
        color: white;
    }
    // 
    .btn-group {
        .dropdown-toggle{width:180px;height:52px;border:1px solid #fff !important;background-color: transparent !important;font-weight:400;font-size:14px!important;color:#5a5e6a;display:flex;align-items:center;justify-content:space-between}
        .dropdown-menu {width: 180px; padding: 10px 1rem; margin-top: 5px;
            .dropdown-item { padding: 0.75rem 0; }
        }
    }
}
.footer {
    background-color: transparent; border-top: 1px solid #353745; margin-top: 3rem; 
    .data-wrap {font-size: 12px; display: flex; color: white; width: 100%;}
    .data-label {color: #A5A9BD; width: 70px;}
}

.dropdown-menu { min-width: 80px; transform: translate(-7px, 19px) !important; cursor: pointer;}
.dropdown::marker {display: none; content: none;}
.dropdown-toggle::after {display: none;}
// 
.modal-img {
    .modal-dialog {margin: 0 auto;}
    .modal-content {background-color: transparent;}
}
.accordion-button {display: block;padding: 1rem 0}
.accordion-button::after {display: none;}
.accordion-button:not(.collapsed), .accordion-button:focus { color: inherit; background-color: white; box-shadow: none; border-radius: 0 !important;}
.accordion-item { border: none; border-bottom: 1px solid #EEE; border-radius: 0;}
.accordion-body {padding: 1rem 0}
.side-menu { 
    display: flex; flex-direction: column; margin-top: 5rem; position: sticky; top: 5rem;
    .avatar {width: 40px; height: 40px;}
}
.ads-wrap {
    min-height: 2600px;
    .ads-inner {position: sticky; top: 5rem; min-height: 300px;}
}
.ads-wrap:last-child {min-height: 800px;}
ins.adsbygoogle[data-ad-status="unfilled"] { display: none !important; }
.toast-msg { text-align: center; width: 100%; position: fixed; bottom: 2rem; font-weight: 700; }
.yarl__container {
    background-color: #505050;
    .yarl__icon { height: var(--yarl__icon_size,26px); width: var(--yarl__icon_size,26px); }
    .yarl__slide { padding: 0; }
    // .yarl__navigation_next, .yarl__navigation_prev, 
    // .yarl__toolbar button:nth-child(1), .yarl__toolbar button:nth-child(2) {display: none;}
}
.report-modal {
    .modal-body {padding: 20px;}
    .item {border-radius: 10rem !important; width: 100%; min-height: 47px !important; height: auto; color: #070B11; background-color: #F1F1F1; font-size: 16px !important; display: flex; align-items: center; justify-content: center; margin-bottom: 10px;}
    .item:hover, item.on {color: white; background-color: $primary;}
    textarea {background-color: #F0F1F6 !important; min-height: 100px; color: black !important;}
}
.payment-modal {
    .form-control, .form-control:focus {color: black !important; background-color: white !important; border: 1px solid #aaa !important;}
}
.ql-snow .ql-tooltip input[type=text] {color: black;}
.ql-editor.ql-blank::before { font-style: normal !important; }
.ql-editor{ min-height:20rem; 
    .ql-video { width: 100%; height: 320px; }
}
.ql-color .ql-picker-options [data-value=color-picker]:before { content: 'Pick Color'; }
.ql-color .ql-picker-options [data-value=color-picker] { background: none !important; width: 100% !important; height: 25px !important; text-align: center; color: blue; text-decoration: underline; }
.ql-toolbar.ql-snow {position: sticky; top: 64px; background-color: white; z-index: 9;}
.quill.ko {
    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {content: '제목 1';}
    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {content: '제목 2';}
    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {content: '제목 3';}
    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {content: '제목 4';}
    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {content: '제목 5';}
    .ql-snow .ql-picker.ql-header .ql-picker-label::before, .ql-snow .ql-picker.ql-header .ql-picker-item::before {content: '일반';}
    .ql-color .ql-picker-options [data-value=color-picker]:before { content: '선택'; }
}

.home {
    .tag-wrap {overflow-x: auto;position: sticky; top: 60px; z-index: 9; background: #1F212C; width: 100%;}
    .nav-tabs { flex-wrap: wrap; justify-content: center;
        .nav-item {flex:1}
        .nav-link {font-size: 15px;}
    } 
}

.login {
    .email-auth-complete {font-size: 12px; position: absolute; top: 45px; right: 12px;}
    .all-agree .form-check-label {font-size: 16px; font-weight: 700;}
    .timerStr { font-size: 10px; color: #A5A9BD; position: absolute; top: 6px; right: 16px;}
    .valid-feedback { font-size: 10px; color: #070B11; }
    @media (hover: hover) and (pointer: fine) {
        .btn-primary:hover {background-color: $primary !important; color: white !important; }
    }
    .btn-google + div {display: none;}
}
.t-main.login {margin-top: 100px;}
.follower-wrap { overflow-x: auto; display: flex;
    .item {display: inline-block; width: 66px; margin-right: 17px; text-align: center;
        .follower {width: 56px; height: 56px; margin-bottom: 5px; border-radius: 20px; background-color: #353745; color: white;}
    }
}
.contents-detail {
    .thumb {width: 100%; height: 520px; background-size: cover; background-position: center; background-repeat: no-repeat; cursor: pointer;}
    // 
    .thumb-wrap {
        .full-size {position: relative; width: calc(100% - 150px); margin: auto;}
        video {height: calc(100vh - 80px);}
    }
    .swiper-prev, .swiper-next {position: absolute; background-position: 50%; background-repeat: no-repeat; background-size: 100% 100%; width: 86px !important; height: 86px !important; top: calc(50% - 30px) !important; cursor: pointer;}
    .swiper-prev { background-image: url('../assets/img/slider_prev.png'); left: -92px; }
    .swiper-next { background-image: url('../assets/img/slider_next.png'); right: -92px; }
    .swiper-next.swiper-button-disabled, .swiper-prev.swiper-button-disabled { opacity: .35; cursor: auto; pointer-events: none; }
    // 
    .recommend-wrap { display: flex; gap: 12px;
        .contents-item { width: 33.3%;
            .top-wrap {padding: 20px;}
            .thumb-wrap { padding: 0 20px;
                .thumb {height: 240px;}
                .txt-wrap {padding: 20px 0 10px;}
            }
            .card-body {padding: 15px 20px 20px;}
        } 
    }
    
    .chatgpt-wraper .p-4 { padding:1rem !important }
    .ql-video { width: 100%; height: 520px; }
    .lh20.word-break-all a {color: white; text-decoration: underline;}
    .descript-wrap {
        word-break: break-all;
        h2 {font-size: 20px;}
        h3 {font-size: 18px;}
        ol, ul {
          padding-left: 1.5em;
        }
        ol > li,
        ul > li {
          list-style-type: none;
        }
        ul > li::before {
          content: '\2022';
        }
        li::before {
          display: inline-block;
          white-space: nowrap;
        }
        li:not(.ql-direction-rtl)::before {
          margin-left: -1.5em;
          margin-right: 0.3em;
          text-align: right;
        }
        li.ql-direction-rtl::before {
          margin-left: 0.3em;
          margin-right: -1.5em;
        }
        ol li:not(.ql-direction-rtl),
        ul li:not(.ql-direction-rtl) {
          padding-left: 1.5em;
        }
        ol li.ql-direction-rtl,
        ul li.ql-direction-rtl {
          padding-right: 1.5em;
        }
        ol li {
          counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
          counter-increment: list-0;
        }
        ol li:before {
          content: counter(list-0, decimal) '. ';
        }
    }
}
.prompt-wrap { padding: 20px; background-color: #16171C; border: 1px solid #353745; border-radius: 4px;
    .prompt {padding: 10px; font-size: 12px; color: #F1F1F1; border-radius: 4px !important; background-color: #353745 !important; margin-bottom: 10px; flex: 1; margin-left: 5px; word-break: break-word;}
}
.comment-wrap { background-color: #16171C; border: 1px solid #353745; border-radius: 4px; transition: all 0.3s ease-out;
    .comment-item { padding: 10px 0; border-top: 1px solid #353745; margin-bottom: 15px;}
    .comment-show {height: 476px; overflow-y: auto; position: relative;}
    .send-comment { padding: 10px; border-top: 1px solid #1F212C; display: flex; align-items: center; background-color: #16171C;
        .comment-input {border-radius: 30px; background-color: #353745; padding: 6px 10px; display: flex; flex: 1; align-items: center;}
        .input-cmt {flex: 1; max-height: 10rem; overflow: scroll; padding-top: 10px;}
    }
}
.comment-modal {
    .modal-content, .modal-content {background-color: transparent; border-radius: 20px 20px 0px 0px !important;}
    .modal-body, .modal-body {padding: 0 !important;}
    .comment-wrap {border: none;}
}
.aigee {
    .video-wrap {height: calc(100vh - 80px); width: 100%; display: flex; align-items: center; justify-content: center; position: relative; cursor: pointer; overflow: hidden;
        .spinner-wrap {width: 100%; height: 100%; position: absolute; display: flex; align-items: center; justify-content: center;}
    }
    .video-wrap.center {position: absolute; top: 0; left: 0;}
    .main-video {width: 100%;}
    .side-wrap {position: absolute; bottom: 0px; right: 20px;
        .action {margin-bottom: 24px; text-align: center; cursor: pointer;}
        .dropdown-menu { transform: translate(-7px, -11px) !important; }
    }
    .bottom-wrap {position: absolute; bottom: 0px; left: 0; padding: 24px; width: calc(100% - 64px); transition: all 0.3s ease-out; max-height: 80vh; overflow-y: scroll;}
    .i_like1 { width: 48px; height: 48px; animation: heartbeat 0.2s; transition: all 0.3s ease-out;}
    @keyframes heartbeat
    {
        0%
        {
            transform: scale( 0 );
        }
        80%
        {
            transform: scale( 1.1 );
        }
        100%
        {
            transform: scale( 1 );
        }
    }
    // 
    .like-container {width: 48px;  height: 48px; position: relative;}
    .like-btn{  
        position: absolute; 
        cursor: pointer;
        width: 48px; 
        height: 48px;  
        border-radius: 100%;
        text-align: center;
        z-index: 10;
    }
    .react-swipeable-view-container {min-height: 100%; height: -webkit-fill-available;}
}
.search {
    .index {background-color: #353745; padding: 4px 15px; border-radius: 100px;}
    .search-input {
        .i_search {left: 15px;}
        input {padding-left: 50px;}
    } 
    .tag-wrap { background: #1F212C; z-index: 9; position: sticky; position: -webkit-sticky; top: 60px;}
}
.reg-post-modal {
    .modal-content {background-color: transparent;}
}
.reg-post-item { background-color: transparent; gap: 10px; padding: 20px; display: flex; flex-direction: column;
    .item {background-color: #353745; border-radius: 10px; padding: 24px 20px; cursor: pointer;
        a {color: $secondary; display: flex; align-items: center;}
    }
}
.reg-post {
    .img-wrap {position: relative; border-radius: 4px; background-color: #353745; overflow: hidden;display: flex; align-items: center; justify-content: center; border: 2px dashed $primary;}
    .i_delete {width: 44px; height: 44px; cursor: pointer; position: absolute; top: 17px; right: 15px;}
    .video-upload {background-color: #16171C; padding: 40px 20px; border-radius: 4px; display: flex; align-items: center; justify-content: center; text-align: center; cursor: pointer; border: 2px dashed $primary;}
    .btn-play { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); cursor: pointer;}
    .prompt-wrap { background-color: #1F212C; }
    .comment-wrap {border: none; overflow: hidden;}
    .send-comment { padding: 0; border-top: none; background-color: #1F212C; }
}
.bookmark {
    .bookmark-wrap { display: flex; flex-wrap: wrap; gap: 10px;
        .item-wrap {display: flex; flex-wrap: wrap; gap: 2px; border-radius: 4px; overflow: hidden; min-height: 87%;
            .bookmark-img {width: 100%; height: 100%; aspect-ratio: 1; background-position: center; background-repeat: no-repeat; background-size: cover; display: flex; align-items: center; justify-content: center; word-break: break-all; font-size: 12px; background-color: #16171c;}
        }
        .item {width: calc(100% / 3 - 7px); cursor: pointer;}
        .item-wrap.multi .bookmark-img {width: calc(100% / 2 - 1px); }
    }
}
.reg-folder {
    .folder-wrap { display: flex; flex-wrap: wrap; gap: 3px;
        .item { border-radius: 4px; overflow: hidden; width: calc(100% / 4 - 3px); position: relative; cursor: pointer;
            .folder-img {width: 100%; aspect-ratio: 1; background-position: center; background-repeat: no-repeat; background-size: cover; display: flex; align-items: center; justify-content: center; word-break: break-all; font-size: 12px;}
            .check {width: 24px; position: absolute; top: 1px; right: 1px;}
        }
    }
    .dropdown-toggle {padding: 5px;}
    .dropdown-menu { min-width: 100px; background: #353745; 
        .dropdown-item { color: #F6F7FA !important; padding: 9px 0;}
    }
    .prompt-item {
        background-color: #16171C; padding: 20px; border-radius: 4px; margin-bottom: 10px; position: relative;
    }
}
.mypage {
    .folder-wrap {
        .item {display: flex; align-items: center; justify-content: center; width: calc(100% / 3 - 2px); background-color: #16171c; border: 1px solid #353745; // border: 1px solid #393939;
            .folder-img {width: 100%; height: 100%; aspect-ratio : 1 / 1; display: flex; align-items: center; justify-content: center; text-align: center;}
        }
    }
    .tag-wrap { position: sticky; top: 63px; left: 0; z-index: 9; background-color: #1F212C;}
    .contents-item .thumb-wrap .thumb {height: 240px;}
    .react-swipeable-view-container { min-height: 32vh; }
}
.profile {
    .side-bar {
        .i_setting, .i_camera, .member-ship, .btn-edit-profile {display: none !important;}
        .btn-small {display: block !important;}
        .avatar-xl {width: 64px; height: 64px;}
    }
    .search-input {
        .i_search { left: 15px; z-index: 1;}
        .nickname { position: absolute; top: 12px; left: 45px;}
        input {padding-left: 87px;}
    }
}
.setting {
    ul.set-ul li {padding: 16px 20px; font-size: 16px; display: flex; align-items: center; justify-content: space-between; cursor: pointer; word-break: break-word;}
}
.setting-list {
    .top-item {text-align: center; cursor: pointer; padding: 15px 20px; border-radius: 4px; flex: 1;}
}
.faq {
    .category-wrap { overflow-x: auto; padding: 5px 0 10px;
        .category-inner-wrap {display: flex; white-space: nowrap; }
        .item {font-weight: 700; color: #F6F7FA; padding: 8px 17px; border-radius: 10rem; background-color: #1F212C; margin-right: 2px; cursor: pointer; margin-bottom: 5px;}
        .item.on, .item:hover { color: #1F212C; background-color: #F6F7FA;}
    }
    .category-wrap::-webkit-scrollbar { width: 20px; height: 5px; }
    .category-wrap::-webkit-scrollbar-thumb { background: rgba(255,255,255,0.7); }
    .accordion-item, .accordion-item .accordion-button {background-color: #1F212C; color: #F6F7FA; border-radius: 0;}
    .accordion-item {border-color: #353745;}
    .i_up, .collapsed .i_down {display: block;}
    .i_down, .collapsed .i_up {display: none;}
}
// .user-contents {
//     .top-header { position: fixed; top: 6px; z-index: 1035; width: 578px;}
// }

/* **************************************** *
  * MOBILE
  * **************************************** */
@media (min-width: 1400px) {
    // .container-xl, .container-lg, .container-md, .container-sm, .container {
    //     max-width: 1200px;
    // }
    // .container-xxl { max-width: 1400px; }
}

@media (max-width: 1400px) {
    
}

@media (max-width: 1200px) {
    
}

@media (max-width: 1024px) {
    
}

@media (max-width: 992px) {
    // textarea {
    //     background-repeat: no-repeat; background-origin: content-box; background-size: 15px; box-sizing: border-box; cursor: se-resize; background-image: url('../assets/img/i_resize.png'); background-position: 104% 104%;
    //     height: auto;
    // }
}
  
@media (max-width: 767px) {
    .t-main {min-height: 100vh;}
    .modal .btn-group .dropdown-toggle {width: 26px; padding: 0;}
    .tab-wrap {overflow-x: scroll;}
    .tab-inner {width: 200vw;}
    .no-data { padding: 8rem 0;}
    .border-13 {border: none;}
    .right-dummy {display: none;}
    .header { 
        //display: none;
        .logo {width: 62px; margin-top: 4px;}
        .alarm { margin-right: 0;
            span{top:5px;right:1px;}
        }
    }
    .side-menu {display: none;}
    .contents-item { border: none; border-top: 1px solid #353745;
        .top-wrap {padding: 20px 10px 10px;}
        .thumb-wrap {padding: 0;
            .thumb {height: 240px; 
                .i_play {width: 70px;}
            }
            .txt-wrap {padding: 10px;}
        }
        .card-body {padding: 11px 10px 20px;}
        .chatgpt-wraper .p-4 {padding: 10px !important;}
    }
    .reg-post-item { 
        .item { padding: 17px; }
    }
    .mobile-bottom-menu { position: fixed; bottom: 0; width: 100%; z-index: 50; left: 0; transition: all,.3s; height: 60px; background-color: #1F212C;}
    .mobile-bottom-menu ul { width: 100%; display: flex; }
    .mobile-bottom-menu li { background: #1F212C; text-align: center; flex: 1; 
        a { display: flex; align-items: center; justify-content: center; height: 60px; }
        img { width: 24px; }
        .avatar-md {width: 32px; height: 32px;}
    }
    .ql-editor{ min-height:8rem; 
        .ql-video { height: 220px; }
    }
    .ql-toolbar.ql-snow {top: 0;}
    
    .report-modal { .modal-dialog {margin: 10px;} }
    // 
    .login {
        .next-wrap {position: absolute; bottom: 20px; left: 0; width: 100%;}
        .register-complete {display: flex; flex-direction: column; align-items: center; justify-content: space-between; flex: 1; min-height: 95vh;}
    }
    .t-main.login {margin-top: 0; min-height: 70vh;}
    // .home .tag-wrap {
    //     // margin-left: 3px;
    //     .nav-item:first-child a {padding-left: 20px;}
    // }
    .contents-detail {
        .thumb-wrap { width: 100vw; margin-left: -12px; 
            .thumb {height: 240px;}
            video {height: auto;}
        }
        .recommend-wrap {display: block}
        .contents-item {width: 100% !important;}
        .chatgpt-wraper {
            margin: 0 -12px;
            .p-4 { padding: 12px !important; }
        }
        .ql-video { height: 250px; }
    }
    .prompt-wrap {background-color: #1F212C; padding: 14.5px 20px; border-radius: 6px;
        .align-items-center:not(.w-100) {display: block;}
        .prompt {margin-left: 0;}
    }
    .comment-wrap {background-color: #1F212C; border-radius: 6px;
        .comment-show {height: 60vh;}
        .send-comment {padding: 10px;}
    }
    .aigee {
        .swipeable-wrap {position: fixed; top: 0; left: 0; width: 100%;}
        .video-wrap {
            // height: calc(100dvh - 60px);
            height: 100vh;
            height: calc(var(--vh, 1vh) * 100 - 60px);
        }
        // .side-wrap {bottom: 60px; }
        // .bottom-wrap { bottom: 60px; }
    }
    .search {
        .search-header { background: #1F212C; z-index: 9; position: fixed; top: 0px; left: 0; width: 100%; }
        .tag-wrap { top: 51px; }
    }
    .reg-post {
        .btn-reg {width: 100%; height: 50px; background-color: #00759B; position: fixed; bottom: 0; left: 0; display: flex; align-items: center; justify-content: center; font-weight: 700; font-size: 16px;}
        .video-upload {padding: 20px;}
        .comment-wrap { position: fixed; bottom: 0; width: 100%; left: 0;}
    }
    .bookmark {
        .tab {flex: 1;}
        .bookmark-wrap { 
            .item-wrap {
                .bookmark-img {width: calc(100vw / 2 - 18px); height: calc(100vw / 2 - 18px);}
            }
            .item {width: calc(100vw / 2 - 18px);}
            .item-wrap.multi .bookmark-img {width: calc((100vw / 2 - 18px) / 2 - 1px); height: calc((100vw / 2 - 18px) / 2 - 1px); font-size: 10px;}
        }
    }
    .reg-folder {
        .folder-wrap { display: flex; flex-wrap: wrap; gap: 3px;
            .item { border-radius: 4px; overflow: hidden; width: calc(100vw / 3 - 10px); position: relative; cursor: pointer;
                .folder-img {width: calc(100vw / 3 - 10px); height: calc(100vw / 3 - 10px); background-position: center; background-repeat: no-repeat; background-size: cover; font-size: 10px;}
                .check {width: 24px; position: absolute; top: 1px; right: 1px;}
            }
        }
    }
    .side-bar { padding: 20px; border-radius: 0; }
    .mypage {
        .tag-wrap { top: 0;}
    }
    // .user-contents.profile, div:has(.setting) { background-color: #070B11 !important; }
    // .user-contents {
    //     .top-header { position: unset; width: 100%;}
    // }
    .faq .category-wrap {margin: 0 -23px;}   
}

@media (max-width: 657px) {
    
}
@media (max-width: 576px) {
    
}