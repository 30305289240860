
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/noto-sans-kr-v12-korean_latin-300.eot'); /* IE9 Compat Modes */
  src: local('Noto Sans KR Light'), local('NotoSansKR-Light'),
       url('../assets/fonts/noto-sans-kr-v12-korean_latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/noto-sans-kr-v12-korean_latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/noto-sans-kr-v12-korean_latin-300.woff') format('woff'), /* Modern Browsers */
      //  url('../assets/fonts/noto-sans-kr-v12-korean_latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/noto-sans-kr-v12-korean_latin-300.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}
/* noto-sans-kr-regular - korean_latin */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/noto-sans-kr-v12-korean_latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Noto Sans KR Regular'), local('NotoSansKR-Regular'),
       url('../assets/fonts/noto-sans-kr-v12-korean_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/noto-sans-kr-v12-korean_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/noto-sans-kr-v12-korean_latin-regular.woff') format('woff'), /* Modern Browsers */
      //  url('../assets/fonts/noto-sans-kr-v12-korean_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/noto-sans-kr-v12-korean_latin-regular.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}
/* noto-sans-kr-500 - korean_latin */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/noto-sans-kr-v12-korean_latin-500.eot'); /* IE9 Compat Modes */
  src: local('Noto Sans KR Medium'), local('NotoSansKR-Medium'),
       url('../assets/fonts/noto-sans-kr-v12-korean_latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/noto-sans-kr-v12-korean_latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/noto-sans-kr-v12-korean_latin-500.woff') format('woff'), /* Modern Browsers */
      //  url('../assets/fonts/noto-sans-kr-v12-korean_latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/noto-sans-kr-v12-korean_latin-500.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}
/* noto-sans-kr-700 - korean_latin */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/noto-sans-kr-v12-korean_latin-700.eot'); /* IE9 Compat Modes */
  src: local('Noto Sans KR Bold'), local('NotoSansKR-Bold'),
       url('../assets/fonts/noto-sans-kr-v12-korean_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/noto-sans-kr-v12-korean_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/noto-sans-kr-v12-korean_latin-700.woff') format('woff'), /* Modern Browsers */
      //  url('../assets/fonts/noto-sans-kr-v12-korean_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/noto-sans-kr-v12-korean_latin-700.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}
/* noto-sans-kr-900 - korean_latin */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 900;
  src: url('../assets/fonts/noto-sans-kr-v12-korean_latin-900.eot'); /* IE9 Compat Modes */
  src: local('Noto Sans KR Black'), local('NotoSansKR-Black'),
       url('../assets/fonts/noto-sans-kr-v12-korean_latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/noto-sans-kr-v12-korean_latin-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/noto-sans-kr-v12-korean_latin-900.woff') format('woff'), /* Modern Browsers */
      //  url('../assets/fonts/noto-sans-kr-v12-korean_latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/noto-sans-kr-v12-korean_latin-900.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}

html {background-color: $black;}
body { 
  font-family: "Noto Sans KR", sans-serif;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  font-size: 14px; font-weight: 300; letter-spacing: -.3px;
  background-color: $black;
  color: #F6F7FA;
  position: relative;
  overflow-x: hidden;
  // user-select: none;
}
img, a {
  // user-drag: none; 
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  max-width: 100%;
}
ul {list-style: none; padding: 0; margin: 0;}
li {list-style: none;}
a {color: #000000; text-decoration: none;cursor: pointer;}
a:hover {color: #333;}
// common
.c-app {color: black;}
.c-wrapper {position: relative;}
.t-main {min-height: 77vh;}
.box-shadow {box-shadow: 1px 2px 16px 0 rgba(0, 0, 0, 0.1) !important;}
.box-shadow1 {box-shadow: 1px 2px 16px 0 rgba(255, 113, 113, 0.16) !important;}
.white-bg { background: #FFF; }
.bg-1 { background: #F1F1F1; }
.bg-2 { background: #FFF0F0; }
.bg-3 { background: #353745; }
.bg-9 { background: #3B3F4A; }
.bg-12 { background-color: #121214; }
.bg-dark {background-color: #000 !important;}
.bg-black {background-color: #070B11 !important;}
.bg-primary2 {background-color: rgba(0, 117, 155, 0.8);}
.bg-trans { background-color: transparent !important; }
.z-top {z-index: 999999; position: relative;}
.minh-5 {min-height: 5rem !important;}
.minh-10 {min-height: 10rem !important;}
.minh-20 {min-height: 20rem !important;}
.minh-38 {min-height: 38rem !important;}
.h-44px {height: 44px !important;}
.h-48px {height: 48px !important;}
.h-50px {height: 50px !important;}
.h-52px {height: 52px !important;}
.h-60px {height: 60px !important;}
.max100 { max-width: 100%; }
.max90 { max-width: 90%; }
.max80 { max-width: 80%; }
.maxw-10 { max-width: 10rem; }
.maxw-54 { max-width: 54px; }
.width50 { width: 50% !important; display: inline-block; }
.w-30 { width: 30% !important; display: inline-block; }
.w-20 { width: 20% !important; display: inline-block; }
.width24 { width: 24rem !important; }
.width23 { width: 23rem !important; }
.width21 { width: 21rem !important; }
.width20 { width: 20rem !important; }
.width14 { width: 14rem !important; }
.width13 { width: 13rem !important; }
.width12 { width: 12rem !important; }
.width11 { width: 11rem !important; }
.width10 { width: 10rem !important; }
.width9 { width: 9rem !important; }
.width8 { width: 8rem !important; }
.width7 { width: 7rem !important; }
.width6 { width: 6rem !important; }
.width5 { width: 5rem !important; }
.width4 { width: 4rem !important; }
.width3 { width: 3rem !important; }
.width2 { width: 2rem !important; }
.width1 { width: 1rem !important; }
.h-auto {height: auto;}
.w-4px { width: 4px !important; }
.w-8px { width: 8px !important; }
.w-9px { width: 9px !important; }
.w-10px{width:10px!important}.w-11px{width:11px!important}.w-12px{width:12px!important}.w-13px{width:13px!important}.w-14px{width:14px!important}.w-15px{width:15px!important}.w-16px{width:16px!important}.w-17px{width:17px!important}.w-18px{width:18px!important}.w-19px{width:19px!important}.w-20px{width:20px!important}.w-21px{width:21px!important}.w-22px{width:22px!important}.w-23px{width:23px!important}.w-24px{width:24px!important}.w-27px{width:27px!important}.w-30px{width:30px!important}.w-32px{width:32px!important}
.w-25px{width:25px!important}.w-28px{width:28px!important}.w-36px{width:36px!important}.w-40px{width:40px!important}.w-48px{width:48px!important}.w-55px{width:55px!important}.w-64px{width:64px!important}
.mt-32 { margin-top: 32px;}
.d-Iblock { display: inline-block !important; }
.align-center { align-items: center !important; display: flex;justify-content: center; }
.align-items-center { align-items: center !important; display: flex;}
.justify-content-center {display: flex; justify-content: center; }
.justify-content-between {display: flex; align-items: center; justify-content: space-between;}
.flex-direction {flex-direction: column;}
.flex-1 {flex: 1;}
.strong, strong, b  { font-weight: 700 !important; }
.fw-300 { font-weight: 300 !important; }
.fw-400 { font-weight: 400 !important; }
.fw-500 { font-weight: 500 !important; }
.fw-700, .fw-bold { font-weight: 700 !important; }
.fw-900 { font-weight: 900 !important; }
.trans {transition: all 0.3s ease-out;}
.b-none { border: none !important; }
.b-bottom-none { border-bottom: none !important; }
.border-1 {border: 1px solid #EEE}
.border-2 { border-bottom: 2px solid #5A5E6A; }
.border-radius-10 {border-radius: 10px;}
.border-radius-100 {border-radius: 100%;}
.box-none { box-shadow: none !important; }
.rotate-45 {transform: rotate(45deg);}
.rotate-90 {transform: rotate(-90deg);}
.rotate-180 {transform: rotate(-180deg);}
.btn { font-size: 18px !important; font-weight: 700; text-transform: none; box-shadow: none !important; min-height: 50px; border-radius: 4px; transition: all 0.3s ease-out;}
.btn-check:checked + .btn:focus, .btn-check:active + .btn:focus, .btn:active:focus, .btn.active:focus, .btn.show:focus,
.btn-check:checked + .btn, .btn-check:active + .btn, .btn:active, .btn.active, .btn.show {
  border-color: transparent; //border: none;
}
.btn-check:focus + .btn, .btn:focus {border-color: transparent;}
.btn-sm {margin: 0; padding: .5rem 1.3rem !important; font-size: 0.8rem !important; min-height: 37px;}
.btn-default { background-color: #F4F4EE !important; color: rgba(60, 60, 67, 0.3); }
.btn-default:hover {color: #94969a;background-color: #F4F4EE;}
.btn-default:not([disabled]):not(.disabled):active, .btn-default:not([disabled]):not(.disabled).active, .show > .btn-default.dropdown-toggle {background-color: #F4F4EE !important; color: rgba(60, 60, 67, 0.3);}
.btn-gray { background-color: gray !important; }
.btn-gray1 { background-color: #F4F4EE !important; color: rgba(60, 60, 67, 0.3); }
.btn-black, .btn-black:hover, .btn-black:active { background-color: #000 !important; color: white !important; border: 1px solid black !important;}
.btn-dark {background-color: #1F212C !important; color: #86899D; border: none;}
.btn-danger { background-color: $danger !important; color: white; }
.btn-pink, .btn-pink:hover { background-color: $pink !important; color: white; }
.btn-white { background-color: #FFF !important; color: #666 }
.btn-cancel { background-color: rgba(165, 169, 189, 1) !important; color: #FFF }
.btn-success, .btn-success:hover { background-color: $green !important; color: white; }
.btn-warning { background-color: $yellow !important; color: black; border: none;}
.btn-secondary { background-color: #CCC !important; color: white; border: none !important;}
.btn-primary:hover {color: white;}
@media (hover: hover) and (pointer: fine) {
  .btn-primary:hover {background-color: $primary !important; }
}
.btn-light { background-color: #353745 !important; color: #86899D; border: none !important;}
.btn-light:hover {color: #acb0c9;}
.btn-rounded { border-radius: 10rem; }
.btn-outline-danger { color: $danger !important; border: 2px solid $danger !important; }
.btn-outline-light { border-color: #CCC !important; }
.btn-outline-dark {border: 1px solid black !important; color: black;}
.btn-outline-black {border: 1px solid black !important;}
.btn-outline-gray {border-color: #CCC !important;}
.btn-outline-pink, .btn-outline-pink:hover  {border: 1px solid $pink !important; color: $pink;}
.btn-outline-warning {border-color: $yellow !important; color: $yellow !important; background-color: transparent !important;}
.btn-outline-info {border: 1px solid #7ADBFB !important; color: white !important;}
// .btn[class*="btn-outline-"] { border: 1px solid !important }
.btn-danger.disabled, .btn-danger:disabled { background-color: #F4F4EE !important; color: rgba(60, 60, 67, 0.3); font-weight: bold; }
.btn:disabled, .btn.disabled, fieldset:disabled .btn { color: inherit; border-color: transparent; }
.btn-warning:disabled, .btn-warning.disabled { color: #7F828C; background-color: #3B3F4A !important; }
// button:not(.btn-pill) { font-size: 0.8rem !important; font-weight: 700 !important; border-radius: 3px;}
.btn-pill { border-radius: 50em !important; }
.btn-outline-danger:hover {background-color: #ff35472b !important;}
.bg-danger {background-color: $pink;}
.btn-disable { background-color: #353745; border-radius: 10rem; font-size: 16px; color: #5D6078; font-weight: 700;align-items: center !important; display: flex; justify-content: center; height: 50px;}
.disabled, :disabled {cursor: not-allowed;}
select option:disabled { color: #EEE; }
.c-pointer, .pointer {cursor: pointer !important;}
.c-auto {cursor: auto !important;}
.italic {font-style: italic !important;}
hr {color: #EEE; opacity: 1;}
.wspace-prewrap {white-space: pre-wrap;} 
.word-wrap-break {word-wrap: break-word;}
.word-break-all {word-break: break-all;}
.pre-wrap {white-space: pre-wrap; }
.text { color: #45484d !important; }
.gray-text { color: #86899D !important; }
.gray2-text { color: #5D6078 !important; }
.gray6-text { color: #666 !important; }
.grayC-text { color: #CCC !important; }
.light-text {color: #A5A9BD !important;}
.white-text { color: #F6F7FA !important; }
.mute-text { color: #D5D8DC !important; }
.dark-text { color: #454f64 !important; }
.dark-text1 { color: #62676a !important; }
.black-text { color: #000 !important; }
.pink-text { color: $pink !important; }
.text-light-blue { color: #4E65A0; }
.azure-text { color: #22C4F8; }
.azure-light { color: #9FEE9E; }
.green-text { color: $green !important; }
.danger-text { color: $danger !important; }
.yellow-text { color: $yellow !important; }
.yellow2-text { color: #EEC84E !important; }
.blue-text { color: $primary; }
.under-line { border-bottom: 1px solid !important;}
.underline { text-decoration: underline;}
.v-bottom { vertical-align: bottom !important; }
.v-top { vertical-align: top !important; }
.v-ttop { vertical-align: text-top; }
.v-tbottom { vertical-align: text-bottom; }
.v-init { vertical-align: initial; }
.v-middle { vertical-align: middle !important; }
.v-hidden { visibility: hidden !important; }
.card { border-radius: 0; margin: 0; border: none;}
.view img { border-radius: 1px; }
.b-top { border-top: 1px solid #EEE !important;}
.b-bottom { border-bottom: 1px solid #353745 !important;}
.b-radius-0 { border-radius: 0; }
.b-radius1 { border-radius: 1px !important; }
.b-radius-1 { border-radius: .5rem; }
.b-radius-4 { border-radius: 4px; }
.b-radius-8 { border-radius: 8px; }
.b-radius-10 { border-radius: 10px; }
.p-12 {padding: 12px;}
.position-bottom {position: absolute; bottom: 0; left: 0;}
.overflow-x {overflow-x: scroll;}
.overflow-y {overflow-y: scroll;}
.opacity9 {opacity: .9;}
.opacity8 {opacity: .8;}
.opacity7 {opacity: .7;}
.f-none { float: none !important; }
.fs5 { font-size: 0.5rem !important; }
.fs6 { font-size: 0.688rem !important; }
.fs7 { font-size: 0.75rem !important; }
.fs8 { font-size: 0.813rem !important; }
.fs9 { font-size: 0.938rem !important; }
.fs10 { font-size: 1.063rem !important; }
.fs11 { font-size: 1.125rem !important; }
.fs12 { font-size: 1.25rem !important; }
.fs13 { font-size: 1.375rem !important; }
.fs14 { font-size: 1.4rem !important; }
.fs15 { font-size: 1.5rem !important; }
.fs16 { font-size: 1.6rem !important; }
.fs17 { font-size: 1.75rem !important; }
.fs2 { font-size: 2rem !important; }
.fs21 { font-size: 2.125rem !important; }
.fs3 { font-size: 3rem !important; }
.fs-10{font-size:10px!important}.fs-11{font-size:11px!important}.fs-12{font-size:12px!important}.fs-13{font-size:13px!important}.fs-14{font-size:14px!important}.fs-15{font-size:15px!important}.fs-16{font-size:16px!important}.fs-17{font-size:17px!important}.fs-18{font-size:18px!important}.fs-19{font-size:19px!important}.fs-20{font-size:20px!important}
.fs-24{font-size:24px!important}.fs-28{font-size:28px!important}.fs-32{font-size:32px!important}.fs-36{font-size:36px!important}
.lh10 {line-height: 1rem;}
.lh13 {line-height: 1.3rem;}
.lh15 {line-height: 1.5rem;}
.lh20 {line-height: 2rem;}
.lh-35 {line-height: 35px;}
.lh-46 {line-height: 46px;}
.lh0813 {line-height: 0.813rem;}
.ls32 {letter-spacing: .32px;}
.ls43 {letter-spacing: .43px;}
.ls65 {letter-spacing: .65px;}
.ls-41 {letter-spacing: -.41px;}
.ls-71 {letter-spacing: -.71px;}
.label, .inputTitle {font-size: 0.813rem; font-weight: 400;margin-bottom: 0.188rem !important;}
.form-label {font-size: 12px; color: #BEC1C7; font-weight: 400; span {color: #FF0000}}
.col-form-label {font-size: 14px; color: #BEC1C7; padding-top: 11px;}
h1, .h1 { font-size: 2.125rem; }
h4, .h4 { font-size: 1.25rem; }
h5, .h5 {font-size: 1rem !important;}
.br-7 {border-radius: 6.9px !important;}
.br-0 {border-radius: 0 !important;}
.m--1 {margin-left: -15px; margin-right: -15px;}
.mb-2rem {margin-bottom: 2rem;}
.pr-2rem {padding-right: 2rem;}
.mt--1 {position: relative; top: -1px;}
.mt--2 {position: relative; top: -2px;}
.mt--3 {position: relative; top: -3px;}
.mt--4 {position: relative; top: -4px;}
.mt--5 {position: relative; top: -5px;}
.mt--6 {position: relative; top: -6px;}
.error { color: red; font-style: normal; }
.ellipsis1 {display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; width: 100%; overflow: hidden; text-overflow: ellipsis;}
.ellipsis2 {display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; width: 100%; overflow: hidden; text-overflow: ellipsis;}
.ellipsis3 {display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical; width: 100%; overflow: hidden; text-overflow: ellipsis;}
.ellipsis4 {display: -webkit-box; -webkit-line-clamp: 4; -webkit-box-orient: vertical; width: 100%; overflow: hidden; text-overflow: ellipsis;}
.ellipsis5 {display: -webkit-box; -webkit-line-clamp: 5; -webkit-box-orient: vertical; width: 100%; overflow: hidden; text-overflow: ellipsis;}
.ellipsis-all {-webkit-line-clamp: 999;}
.dropdown-item {font-size: 14px; color: black !important; padding: 5px 0; text-align: center; font-weight: 700;}
.dropdown-item.active, .dropdown-item:active, .dropdown-item:hover, .dropdown-item:focus { background-color: transparent; }
.f-left {float: left;}
.f-right {float: right;}
.clear {clear: both;}
.flex-wrap {flex-wrap: wrap;}
.text-left {text-align: left;}
.text-center {text-align: center;}
.text-right {text-align: right;}
input.security { -webkit-text-security: disc; }
.custom-select:focus,select:focus,.uneditable-input:focus,input[type=color]:focus,input[type=date]:focus,input[type=datetime-local]:focus,input[type=datetime]:focus,input[type=email]:focus,input[type=month]:focus,input[type=number]:focus,input[type=password]:focus,input[type=search]:focus,input[type=tel]:focus,input[type=text]:focus,input[type=time]:focus,input[type=url]:focus,input[type=week]:focus,textarea:focus
{border-color: $yellow !important;outline:0;box-shadow:none !important; background-color: transparent; color: white;}
.form-control, .custom-select, .rs-input { height: 44px; font-size: 14px !important; color: white; border-radius: 4px !important; background-color: #353745 !important; border: none;}
.form-control:focus {box-shadow: none; color: inherit;}
.form-control:disabled, .form-control[readonly] { background-color: #3B3F4A; border-color: #3B3F4A; color: #5A5E6A;}
.was-validated .form-control:valid, .form-control.is-valid {background: none}
.was-validated .form-control:invalid, .form-control.is-invalid { border-color: $danger !important; background: none}
.rs-input { height: 3rem !important;}
.md-form.md-outline label { padding-left: 20px; top: 4px; font-size: 0.9rem;}
.md-form.md-outline {margin-bottom: 1rem;}
// .custom-select {padding-left: 5px; background: #FFF url("/img/arrow-down.svg") no-repeat right .5rem center;}
// .custom-select.custom {padding-left: 0; background: #FFF url("/img/arrow-right.svg") no-repeat right .5rem center;}
.form-select {height: 44px;}
.popover-body {font-size: 0.688rem;white-space: pre-wrap;
  em {font-style: normal; color: $danger;}
}
// .collapse, .collapsing {transition: height 0s ease !important; height: auto !important; animation: none;animation-duration: 0s !important;}
// .collapsing {display: none;}
// .collapse.collapsing {display: block;}
.collapse .descript, .collapsing .descript  { padding: 1rem; font-size: 0.938rem; }
.collapse p a {text-decoration: underline;}
.modal-backdrop {background-color: rgba(0,0,0,0.8);}
.modal.fade.show {z-index: 900000;}
.modal .bottom-wrap .btn {height: 3rem !important;}
.modal .modal-dialog { margin: auto; }
.modal-header { border: none; padding: 0 1rem;}
.modal-dialog .modal-content {border-radius: 10px; border: none;}
.modal-body {padding: 50px 40px;}
.modal-footer {border: none; justify-content: center; padding: 20px 10px 40px;
  button, button:hover {border-radius: 10rem; font-size: 16px !important; min-width: 7rem;}
  button.one {flex: none; width: 10rem;}
  .btn-secondary {background: #CCCCCC; border-radius: 6px; color: white;}
  .btn-primary {background: $pink; border-radius: 6px; color: white;}
}
// 
.tippy-box[data-theme~='cpopover'] {background-color: #FEE;border-radius: 0.7rem; border-color: #FEE; font-size: .9rem;}
.tippy-box[data-theme~='cpopover'][data-placement^='bottom'] > .tippy-arrow::after,
.tippy-box[data-theme~='cpopover'][data-placement^='top'] > .tippy-arrow::after { border-bottom-color: #fee; }
.tippy-box[data-theme~='cpopover'][data-placement^='bottom'] > .tippy-arrow::before,
.tippy-box[data-theme~='cpopover'][data-placement^='top'] > .tippy-arrow::before { border-bottom-color: #FEE; }
.tippy-box[data-theme~='cpopover'][data-placement^='top'] > .tippy-arrow::after { border-top-color: #fee;}
.custom-control-label {cursor: pointer; font-size: 0.938rem;}
.custom-control-input:checked~.custom-control-label::before{color:#916a6c;border-color:#916a6c;background-color:#916a6c;border-radius:50%;width:20px;height:20px;top:3px}
.custom-radio .custom-control-input:checked~.custom-control-label::after{top:7px;left:-20px;width:10px;height:10px;border-radius:50%;background-color:$danger;background-image:none}
.custom-radio .custom-control-label::before {background-color: #916A6C; border-color: #8a7677; ;}
// checkbox
.custom-checkbox .custom-control-label::before {top: 4px; border-radius: 50% !important; }
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {top: 4px; left: -24px;}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before 
{border-radius: 5px;  color: $danger; border-color: $danger; background-color: $danger; width: 16px; height: 16px; top: 4px;}
.form-check-input:checked[type=checkbox] {background-image: url('../assets/img/i_checked.png');}
.form-check-input[type=checkbox] {background-image: url('../assets/img/i_check.png');background-color: transparent; border-color: transparent; height: 27px; width: 27px; margin-top: -2px;}
.form-check-input:focus { box-shadow: none; }
.form-check-input:checked[type=radio] {background-image: url('../assets/img/i_radio_on.png');}
.form-check-input[type=radio] {background-image: url('../assets/img/i_radio_off.png');background-color: transparent; border-color: transparent;height: 20px; width: 20px; margin-top: 2px;}
.form-check-input:checked:disabled {background-image: url('../assets/img/i_radio_disabled.png');}
.form-check-input[type=checkbox]:checked:disabled {background-image: url('../assets/img/i_check_disabled.png');}
.form-check-input[type=radio]+label {font-size: 16px; color: #B7B7B8; margin-left: 1.3rem;}
.form-check-label {cursor: pointer; font-size: 14px; margin-left: 5px;}
.tippy-content { max-width: 100%;}
.tippy-box {box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);}
[data-tippy-root] { min-width: 18rem; }
.product-detail [data-tippy-root] { min-width: 20rem; }
.popover-header { border: none; font-weight: bold; padding-bottom: 0; background-color: transparent; padding-top: 1rem;}
.c-switch { width: 3.188rem; height: 2.138rem;}
.c-switch[class*="-3d"] .c-switch-slider::before { top: 2px; left: 5px; width: 1.738rem; height: 1.738rem; }
.nav-tabs { border-bottom: none; flex-wrap: unset;
  .nav-link{border:none;border-bottom: solid 2px transparent;color:#7F828C;font-size:18px;font-weight:500;height:55px;display:flex;align-items:center;justify-content:center;margin:0; cursor: pointer;padding: 0 5px; transition: all 0.3s ease-out;}
  .nav-link.active { color: white !important; border-bottom: solid 2px $primary !important;} 
  .nav-link:hover, .nav-link:focus { border-color: $primary; }
} 
.nav-tabs .nav-link.active {  background-color: transparent; font-weight: 700;}
.pagination {justify-content: center}
.page-link{padding:0 0 2px;width:28px;height:25px;display:flex;align-items:center;justify-content:center;cursor:pointer;color:#9DA0A8;border:none;border-radius:6px;margin: 0 4px;background: transparent;}
.page-link.active, .active > .page-link {background: transparent; color: white;}
.page-link:focus, .page-link:hover {color: inherit;background: transparent; }
.avatar-xxl { width: 6rem; height: 6rem; }
// 
.animated1 {
  animation-duration: 100ms;
  animation-fill-mode: both;
}
.animated2 {
  animation-duration: 200ms;
  animation-fill-mode: both;
}
.animated3 {
  animation-duration: 300ms;
  animation-fill-mode: both;
}
.fade.show {transition: opacity 0.15s linear; opacity: 1 !important;}
.invalid-feedback {font-size: 10px; margin-top: .25rem; color: $danger;}
// slider
.carousel .carousel-indicators li { width: 8px; height: 8px; border-radius: 100%; margin: 0 .3rem; background-color: #CCC; opacity: 1; background-clip: unset; border: none; }
.carousel-indicators .active {background-color: $pink !important;}
.carousel-control-prev, .carousel-control-next { width: 45px; height: 45px; border-radius: 100%; opacity: 1; top: 39%;}
.carousel-control-prev { left: -65px; }
.carousel-control-next { right: -65px; }
.carousel .carousel-control-prev-icon { background-image: url('../assets/img/slider_prev.png'); }
.carousel .carousel-control-next-icon { background-image: url('../assets/img/slider_next.png'); }
.carousel .carousel-control-prev-icon, .carousel .carousel-control-next-icon { width: 13px; height: 19px; }
//
.swiper-button-next:after, .swiper-button-prev:after {display: none;}
.swiper-button-prev, .swiper-button-next {position: absolute; background-position: 50%; background-repeat: no-repeat; background-size: 100% 100%; width: 86px !important; height: 86px !important; top: calc(50% - 60px) !important; }
.swiper-button-prev { background-image: url('../assets/img/slider_prev.png'); }
.swiper-button-next { background-image: url('../assets/img/slider_next.png'); }
.swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled { opacity: .35; cursor: auto; pointer-events: none; }
.swiper-pagination span { width: 6px; height: 6px; border-radius: 100%; margin: 0 .3rem; background-color: white; opacity: .5; border: none; }
.swiper-pagination .swiper-pagination-bullet-active { background-color: #00759B !important; opacity: 1; width: 23px; border-radius: 10rem;}
.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction 
{ position: unset; margin-top: 10px;}
// 
.rs-popover {max-width: 20rem;
  span {color: $danger;}
}
// global
::-webkit-input-placeholder {color: #A5A9BD !important;font-size: 0.9rem; font-weight: 300;} /* Edge */
:-ms-input-placeholder {color: #A5A9BD !important;font-size: 0.9rem; font-weight: 300;} /* Internet Explorer 10-11 */
::placeholder {color: #A5A9BD !important;font-size: 0.9rem; font-weight: 300; letter-spacing: -0.75px;}
// input [number] spin disable
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* =============
  Tables
============= */
table {
  border: none; color: white !important; font-size: 16px;
  tr {border-bottom: 1px solid #3B3F4A !important;}
  th, td {padding: 24px 0 !important;}
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* width */
::-webkit-scrollbar {
  width: 0;
  height: 0;
}
@media (min-width: 768px) {
  .fs-md-10{font-size:10px!important}.fs-md-11{font-size:11px!important}.fs-md-12{font-size:12px!important}.fs-md-13{font-size:13px!important}.fs-md-14{font-size:14px!important}.fs-md-15{font-size:15px!important}.fs-md-16{font-size:16px!important}.fs-md-20{font-size:20px!important}
  .fs-md-24{font-size:24px!important}.fs-md-32{font-size:32px!important}.fs-md-36{font-size:36px!important}
  .w-md-10px{width:10px!important}.w-md-11px{width:11px!important}.w-md-12px{width:12px!important}.w-md-13px{width:13px!important}.w-md-14px{width:14px!important}.w-md-15px{width:15px!important}.w-md-16px{width:16px!important}.w-md-17px{width:17px!important}.w-md-18px{width:18px!important}.w-md-19px{width:19px!important}.w-md-20px{width:20px!important}.w-md-21px{width:21px!important}.w-md-22px{width:22px!important}.w-md-23px{width:23px!important}
  .w-md-24px{width:24px!important}
  .width-md-24{width:24rem!important}.width-md-23{width:23rem!important}.width-md-21{width:21rem!important}.width-md-20{width:20rem!important}.width-md-14{width:14rem!important}.width-md-13{width:13rem!important}.width-md-12{width:12rem!important}.width-md-11{width:11rem!important}.width-md-10{width:10rem!important}.width-md-9{width:9rem!important}.width-md-8{width:8rem!important}.width-md-7{width:7rem!important}.width-md-6{width:6rem!important}.width-md-5{width:5rem!important}.width-md-1{width:1rem!important}
  .p-md-20 {padding: 20px !important;}
  .px-md-12 {padding: 12px !important;}
  .lh-md-13 {line-height: 1.3rem;} .lh-md-15 {line-height: 1.5rem;} .lh-md-20 {line-height: 2rem;}
}
@media (max-width: 767px) {
  .swiper-pagination span {width: 6px; height: 6px;}
  .swiper-btn-wrap {bottom: 0;right: 0;}
  .swiper-prev, .swiper-next {width: 36px; height: 36px;margin-left: 0.5rem;}
  .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {bottom: 1rem !important;padding-left: 1rem;}
  .fs-sm-10 {font-size: 1.063rem;}
  .fs-sm-9 {font-size: 0.938rem;}
  .rs-popover {max-width: 12rem;}
  .px-sm-0px {padding-left: 0 !important; padding-right: 0 !important;}
  .px-sm-12 {padding-left: 12px !important; padding-right: 12px !important;}
  .w-sm-100 {width: 100% !important;}
  .bg-sm-1 {background-color: #16171C !important;}
  .nav-tabs { 
    .nav-link, .nav-link:hover, .nav-link:focus{border:none;border-bottom: solid 2px transparent;color:#7F828C;font-weight:500;display:flex;align-items:center;justify-content:center;margin:0; }
  } 
  .modal .modal-dialog { margin: 20px; }
  .modal-footer {padding: 20px 5px;
    button.one {width: 100%;}
  }
  .nav-tabs {
    .nav-link{font-size:16px;height:47px;}
  } 
  .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction 
  { margin-top: 1px;}
  ::-webkit-scrollbar {
    width: 5px;
    height: 2px;
    display: none;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,0.2); 
  }
  [data-tippy-root] { min-width: 100%; }
}
// 
@media (min-width: 576px) {
  .modal-md {
    .modal-dialog {max-width: 400px;}
  }
}
/* Track */
::-webkit-scrollbar-track {
  background: rgba(0,0,0,0.05); 
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,0.3); 
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0,0,0,0.7); 
}